<template>
  <div class="relative min-h-screen">
    <div v-if="mode == 'newPurchase'">
      <div class="  mb-3">
        <div
          class=" w-full bg-white  p-6 rounded-lg md:w-6/12 mb-3 md:mb-6  ml-auto mr-auto "
        >
          <h1 class="font-bold text-lg text-center text-darkblue">
            Seller's Details
          </h1>

          <ValidationObserver v-slot="{ handleSubmit }">
            <form
              ref="customersForm"
              @submit.prevent="handleSubmit(SubmitPurchase)"
            >
              <div class="mb-4 ">
                <label class="block  md:text-xs " for="stock_type">
                  Seller
                </label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <t-rich-select
                    name="Seller"
                    v-model="sellerIndex"
                    placeholder="Select Seller"
                    :fixedClasses="rich_select_component_classes"
                    :classes="rich_select_default_component_classes"
                    :options="sellers"
                  ></t-rich-select>

                  <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div v-if="typeof sellerIndex == 'number'" class="mb-4">
                <p class="  md:text-xs  mb-3">
                  <span class="text-darkblue  md:text-xs  font-bold">
                    Seller Name
                  </span>
                  : {{ selectedSeller.name }}
                </p>
                <p class="mb-3  md:text-xs ">
                  <span class="text-darkblue  md:text-xs  font-bold"
                    >Seller Email
                  </span>
                  : {{ selectedSeller.email }}
                </p>
                <p class="mb-3  md:text-xs ">
                  <span class="text-darkblue  md:text-xs  font-bold"
                    >Seller Phone </span
                  >: {{ selectedSeller.phoneNumber }}
                </p>
                <p class="mb-3  md:text-xs ">
                  <span class="text-darkblue  md:text-xs  font-bold"
                    >Seller Address </span
                  >: {{ selectedSeller.address }}
                </p>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <div v-if="tableData.length > 0" class="mt-6">
        <t-table
          id="my-table"
          :classes="tableClasses"
          :headers="tableHeader"
          :data="tableData"
        >
          <template slot="column" slot-scope="props">
            <td v-if="props.text === 'action'" :class="props.tdClass">
              <button
                @click="DeletePurchasedItem(props.rowIndex)"
                class="font-bold  md:text-xs    md:pl-3 pl-2 pr-2 md:pr-3 pt-2 pb-2 rounded-lg"
              >
                <font-awesome-icon
                  title="Delete"
                  :class="'text-red-600 hover:text-black text-sm'"
                  :icon="['far', 'trash-alt']"
                />
              </button>
            </td>
            <td v-else :class="props.tdClass">{{ props.text }}</td>
          </template>
        </t-table>
        <p class="text-xs text-darkblue">
          <span class="font-bold">Purchase Total</span> :₦{{ purchaseTotal }}
        </p>
      </div>
      <div class="text-center">
        <button
          @click="mode = 'newPurchaseItem'"
          type="button"
          class="text-darkblue  hover:bg-darkblue border-2 border-darkblue  md:text-xs mb-3  mt-3 hover:text-white mr-2 pl-3 pt-1 pb-1 pr-3  rounded-full"
        >
          Add Purchase Item
        </button>
        <button
          @click="$refs.customersForm.requestSubmit()"
          type="button"
          class="text-white  md:text-xs mb-3  mt-3  bg-green-600 hover:bg-darkblue pl-3 pt-2 pb-2 pr-3  rounded-full"
        >
          <svg
            v-if="loadingSpinner"
            class="inline animate-spin mr-2"
            width="25"
            height="25"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48 25C48 12.2975 37.7025 2 25 2"
              stroke="black"
              stroke-width="4"
            />
            <path
              d="M48 25C48 37.7025 37.7025 48 25 48"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M2 25C2 12.2975 12.2975 2 25 2"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M25 48C12.2975 48 2 37.7025 2 25"
              stroke="#C9000C"
              stroke-width="4"
            />
          </svg>
          {{ $route.params.mode == "new" ? "Create" : "Save" }} Purchase
        </button>
      </div>
    </div>
    <div v-if="mode == 'newPurchaseItem'">
      <div>
        <div
          class="w-full ml-auto mr-auto rounded-lg md:w-8/12 mb-3 md:mb-6   "
        >
          <div class="bg-white p-6 mb-4 rounded-lg">
            <h1 class="font-bold text-lg text-center text-darkblue">
              Purchase Items
            </h1>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(AddPurchasedItem)">
                <div class="mb-4">
                  <label class="block  md:text-xs " for="stock_type">
                    Stock Item
                  </label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <t-rich-select
                      name="Stock item"
                      :required="true"
                      v-model="selectedStock.index"
                      placeholder="Select Stock Item"
                      :fixedClasses="rich_select_component_classes"
                      :classes="rich_select_default_component_classes"
                      :options="stockOptions"
                    ></t-rich-select>
                    <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="mb-4">
                  <label class="block  md:text-xs " for="Selling price"
                    >Unit Purchase Price(₦)</label
                  >
                  <validation-provider
                    rules="required|numeric|min_value:1"
                    v-slot="{ errors }"
                  >
                    <input
                      v-model="selectedStock.unitPrice"
                      type="number"
                      placeholder="Price for 1 unit of stock"
                      name="Selling price"
                      class="border-2 w-full border-darkblue rounded-lg block"
                    />
                    <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="mb-3">
                  <label class="block  md:text-xs " for="unit">
                    Unit
                  </label>

                  <input
                    disabled
                    v-model="selectedStock.unit"
                    type="text"
                    name="Ubit"
                    class="border-2 bg-dashboardbody w-full border-darkblue rounded-lg block"
                  />
                </div>
                <div class="mb-3">
                  <label class="block  md:text-xs " for="sellingQuantity"
                    >Purchased Quantity
                  </label>
                  <validation-provider
                    rules="required|double|min_value:1"
                    v-slot="{ errors }"
                  >
                    <input
                      v-model="selectedStock.quantityPurchased"
                      type="text"
                      name="Purchased quantity"
                      class="border-2 w-full border-darkblue rounded-lg block"
                    />
                    <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <p class="text-center mb-2 mt-2 text-sm">
                  Total: ₦{{ purchasedItemTotal.toLocaleString() }}
                </p>
                <div class="text-center">
                  <button
                    @click="mode = 'newPurchase'"
                    type="button"
                    class="bg-red-700 text-white mb-3   md:text-xs  border border-red-700 mr-2  pl-3 pt-2 pb-2 pr-3  rounded-full"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    class="text-darkblue mb-3   md:text-xs  border border-darkblue  pl-3 pt-2 pb-2 pr-3 ml-auto mr-auto rounded-full"
                  >
                    Add Purchased Item
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import gql from "graphql-tag";
  import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
  import { mapMutations } from "vuex";

  import {
    required,
    numeric,
    alpha_spaces,
    email,
  } from "vee-validate/dist/rules";

  extend("numeric", {
    ...numeric,
    message: "{_field_} must be numbers only",
  });

  extend("email", {
    ...email,
    message: "{_field_} must be a valid email",
  });

  extend("required", required);
  extend("alpha_spaces", {
    ...alpha_spaces,
    message: "{_field_} must be alphabets only",
  });

  extend("min_value", {
    validate(value, { min }) {
      return value >= min;
    },
    params: ["min"],
    message: "{_field_} must be greater or equal to {min}",
  });

  export default {
    name: "ManagePurchase",
    props: {},
    components: { ValidationProvider, ValidationObserver, FontAwesomeIcon },
    apollo: {
      getSellers: {
        query: gql`
          query {
            getSellers {
              _id
              name
              phoneNumber
              email
              address
            }
          }
        `,
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
      },
      getStock: {
        query: gql`
          query {
            getStock {
              _id
              itemName
              unit
              unitSellingPrice
              quantityRemaining
            }
          }
        `,
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
      },
    },
    data() {
      return {
        sellerIndex: "",
        mode: "newPurchase",
        loadingSpinner: false,
        purchaseItemModal: false,

        getStock: [],
        getSellers: [],
        seller: {
          name: "",
          phoneNumber: "",
        },
        selectedSeller: {
          id: null,
          name: "",
          email: "",
          phoneNumber: "",
        },

        selectedStock: {
          id: "",
          index: null,
          itemName: "",
          quantityPurchased: 0,
          unitPrice: 0,
          unit: "",
        },

        icons: {
          pen: require("@/assets/imgs/icons/white-pen.svg"),
        },
        existingCustomerId: null,
        select_component_classes:
          "block w-full pl-3 pr-10 py-2 text-darkblue placeholder-gray-400 transition duration-100 ease-in-out bg-white border-2 border-darkblue rounded-lg  shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",

        rich_select_default_component_classes: {
          wrapper: "",
          buttonWrapper: "",
          selectButton:
            "px-3 rounded-lg  md:text-xs  py-2 text-black transition duration-100 ease-in-out bg-white border-darkblue border-2 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
          selectButtonLabel: "",
          selectButtonPlaceholder: "text-darkblue",
          selectButtonIcon: "text-gray-600",
          selectButtonClearButton:
            "hover:bg-blue-100 text-gray-600 rounded transition duration-100 ease-in-out",
          selectButtonClearIcon: "",
          dropdown:
            "-mt-1 bg-white  md:text-xs  border-b border-gray-300 border-l border-r rounded-b shadow-sm",
          dropdownFeedback: "pb-2  md:text-xs  px-3 text-gray-400 text-sm",
          loadingMoreResults: "pb-2 px-3 text-gray-400 text-sm",
          optionsList: "",
          searchWrapper: "p-2 placeholder-gray-400",
          searchBox:
            "px-3 py-2 bg-gray-50 text-sm  md:text-xs  rounded border focus:outline-none focus:shadow-outline border-gray-300",
          optgroup: "text-gray-400 uppercase text-xs py-1 px-2 font-semibold",
          option: "",
          disabledOption: "",
          highlightedOption: "bg-blue-100",
          selectedOption:
            "font-semibold bg-gray-100 bg-blue-500 font-semibold text-white",
          selectedHighlightedOption:
            "font-semibold bg-gray-100 bg-darkblue font-semibold text-white",
          optionContent: "flex justify-between items-center px-3 py-2",
          optionLabel: "",
          selectedIcon: "",
          enterClass: "",
          enterActiveClass: "opacity-0 transition ease-out duration-100",
          enterToClass: "opacity-100",
          leaveClass: "transition ease-in opacity-100",
          leaveActiveClass: "",
          leaveToClass: "opacity-0 duration-75",
        },
        tableClasses: {
          table:
            "min-w-full text-darkblue divide-y md:text-center divide-gray-100 shadow-sm border-gray-200 border",
          thead: "border-b-2 border-darkblue ",
          theadTr: " md:text-xs ",
          theadTh:
            "px-3 py-2 font-bold md:text-center text-left bg-transparent ",
          tbody: "bg-white divide-y divide-gray-100",
          tr: "rounded-lg  md:text-xs  text-xs ",
          td: "px-3 py-2 whitespace-no-wrap",
          tfoot: "",
          tfootTr: "",
          tfootTd: "",
        },

        rich_select_component_classes: {
          wrapper: "relative",
          buttonWrapper: "inline-block relative w-full",
          selectButton: "w-full flex text-left justify-between items-center",
          selectButtonLabel: "block truncate",
          selectButtonPlaceholder: "block truncate",
          selectButtonIcon: "fill-current flex-shrink-0 ml-1 h-4 w-4",
          selectButtonClearButton:
            "flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6",
          selectButtonClearIcon: "fill-current h-3 w-3",
          dropdown: "absolute w-full z-10",
          dropdownFeedback: "",
          loadingMoreResults: "",
          optionsList: "overflow-auto",
          searchWrapper: "inline-block w-full",
          searchBox: "inline-block w-full",
          optgroup: "",
          option: "cursor-pointer",
          disabledOption: "opacity-50 cursor-not-allowed",
          highlightedOption: "cursor-pointer",
          selectedOption: "cursor-pointer",
          selectedHighlightedOption: "cursor-pointer",
          optionContent: "",
          optionLabel: "truncate block",
          selectedIcon: "fill-current h-4 w-4",
          enterClass: "",
          enterActiveClass: "",
          enterToClass: "",
          leaveClass: "",
          leaveActiveClass: "",
          leaveToClass: "",
        },

        tableHeader: [
          {
            id: "itemName",
            value: "itemName",
            text: "Item Name",
            className: "",
          },
          {
            value: "quantityPurchased",
            text: "Quantity",
            className: "fortunate-table",
          },

          {
            value: "unitPrice",
            text: "Rate(₦)",
            className: "fortunate-table",
          },
          {
            value: "unit",
            text: "Unit",
            className: "fortunate-table",
          },
          {
            value: "itemTotal",
            text: "Total(₦)",
            className: "fortunate-table",
          },
          {
            value: "action",
            text: "Action",
            className: "fortunate-table",
          },
        ],
        tableData: [],
      };
    },
    computed: {
      sellers: function() {
        return this.getSellers.map((seller, index) => {
          return {
            value: index,
            text: seller.name,
          };
        });
      },
      purchaseTotal: function() {
        let purchaseTotal = 0;
        this.tableData.forEach((purchaseItem) => {
          purchaseTotal += Number(purchaseItem.itemTotal);
        });
        return purchaseTotal;
      },
      purchasedItemTotal: function() {
        return (
          Number(this.selectedStock.quantityPurchased) *
          Number(this.selectedStock.unitPrice)
        );
      },

      selectedStockItemIndex: function() {
        return this.selectedStock.index;
      },

      stockOptions: function() {
        return this.getStock.map((stock, index) => {
          return {
            value: index,
            text: stock.itemName,
          };
        });
      },
    },
    watch: {
      sellerIndex(newValue) {
        this.selectedSeller.id = this.getSellers[Number(newValue)]._id;
        this.selectedSeller.name = this.getSellers[Number(newValue)].name;
        this.selectedSeller.phoneNumber = this.getSellers[
          Number(newValue)
        ].phoneNumber;
        this.selectedSeller.email = this.getSellers[Number(newValue)].email;
        this.selectedSeller.address = this.getSellers[Number(newValue)].address;
      },
      purchaseItemModal: function(newValue) {
        if (newValue == false) {
          this.selectedStock = {
            id: "",
            index: null,
            itemName: "",
            quantityPurchased: 0,
            unitPrice: 0,
            unit: "",
          };
        }
      },
      selectedStockItemIndex(newValue) {
        this.selectedStock.unitPrice = 0;
        this.selectedStock.quantityPurchased = 0;
        this.selectedStock.id = this.getStock[Number(newValue)]._id;
        this.selectedStock.unit = this.getStock[Number(newValue)].unit;
        this.selectedStock.itemName = this.getStock[Number(newValue)].itemName;
      },
    },
    methods: {
      ...mapMutations(["setBreadcrumbs"]),

      ShowPurchaseItemModal() {
        this.purchaseItemModal = true;
      },
      DeletePurchasedItem(index) {
        this.tableData.splice(index, 1);
      },
      AddInvoiceItem() {
        const duplicateInvoiceItemCheck = this.tableData.find((invoiceItem) => {
          return invoiceItem.stockId === this.selectedStock.id;
        });

        if (duplicateInvoiceItemCheck != undefined) {
          this.$emit("show-alert", {
            type: "error",
            message: `${this.selectedStock.itemName} has already been used in this invoice`,
          });

          return;
        }

        this.tableData.push({
          itemName: this.selectedStock.itemName,
          quantity: this.selectedStock.quantity,
          unit: this.selectedStock.unit,
          unitPrice: this.selectedStock.unitSellingPrice,
          stockId: this.selectedStock.id,
          itemTotal: this.selectedStock.itemTotal,
          quantityBreakdown: this.selectedStock.quantityBreakdown,
          action: "action",
        });
      },
      SubmitPurchase() {
        if (this.tableData.length < 1) {
          this.$emit("show-alert", {
            type: "error",
            message: `You need to add purchased items before you can save it`,
          });

          return;
        }
        this.SavePurchase();
      },
      async SavePurchase() {
        this.loadingSpinner = true;

        const purchasedItems = this.tableData.map((purchasedItem) => {
          return {
            quantityPurchased: purchasedItem.quantityPurchased,
            unitPrice: purchasedItem.unitPrice,
            stockId: purchasedItem.stockId,
          };
        });
        const purchaseData = {
          sellerId: this.selectedSeller.id,

          purchasedItems: purchasedItems,
        };
        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation createPurchase($purchase: PurchaseInput!) {
                createPurchase(purchase: $purchase) {
                  _id
                  purchaseTotal
                }
              }
            `,
            // Parameters
            variables: {
              purchase: purchaseData,
            },
          });

          this.loadingSpinner = false;

          if (!errors && !!data.createPurchase._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Purchase created successfully`,
            });

            return this.$router.push("/app/purchases");
          }

          if (!!errors && data == null) {
            return this.$emit("show-alert", {
              type: "error",
              message: errors[0].message,
            });
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error creating purchase,check your network or contact your developer`,
          });
        } catch (error) {
          this.loadingSpinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error creating purchase,check your network or contact your developer`,
          });
        }
      },

      AddPurchasedItem() {
        this.tableData.push({
          itemName: this.selectedStock.itemName,
          stockId: this.selectedStock.id,
          quantityPurchased: Number(this.selectedStock.quantityPurchased),
          unitPrice: Number(this.selectedStock.unitPrice),
          unit: this.selectedStock.unit,
          itemTotal: this.purchasedItemTotal,
          action: "action",
        });
        this.$emit("show-alert", {
          type: "notification",
          timeout: 3500,
          message: `${this.selectedStock.itemName} has been added to purchase items`,
        });
        this.selectedStock = {
          id: "",
          index: null,
          itemName: "",
          quantityPurchased: 0,
          unitPrice: 0,
          unit: "",
        };
        this.mode = "newPurchase";
      },
      GetPurchasedItems() {},

      ChangeCustomerMode(mode) {
        this.customerMode = mode;
      },
    },
    mounted() {
      this.setBreadcrumbs([
        { displayName: "Purchases", pathName: "purchases" },

        {
          displayName: "Manage Purchase",
          pathName: "manage_purchase",
          params: { id: this.$route.params.id, mode: this.$route.params.mode },
        },
      ]);
      this.$apollo.queries.getStock.setOptions({
        fetchPolicy: "network-only",
      });
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
  }
</style>
